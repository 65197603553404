import { createReducer, normalizeArray } from "../../store/utils";
import * as actionTypes from "./action-types";
import { denormalizeEntitiesArray } from "../../selectors/utils";
import { APP_CLEAR_STORE } from "../authorization/action-types";

const initialState = {
  event: {
    loading_auction: false,
    items: [],
  },
  instaBid: false,
  buyingPower: { enabled: false, hasLimit: false, limit: 0, remaining: 0, debugMode: false },
  itemsBidOn: [],
  loadingEventLiveRegister: true,
  itemBiddingHistory: []
};

export default createReducer(initialState, {
  [APP_CLEAR_STORE]: () => ({ ...initialState }),
  [actionTypes.INITIALIZE_LIVE_AUCTION]: (state, action) => ({
    ...state,
    event: { ...action.payload.event, items: normalizeArray(action.payload.items), loading_auction: true },
    buyingPower: { ...action.payload.buyingPower },
    itemsBidOn: [...action.payload.itemsBidOn],
  }),
  [actionTypes.REFRESH_LIVE_AUCTION]: (state, action) => {
    const updatedItems = action.payload.items.map((x) => {
      let prevItem = state.event.items[x.id];
      if (prevItem) {
        return { ...x, maxBidAmount: prevItem.maxBidAmount };
      }
      return x;
    });

    return {
      ...state,
      event: { 
        ...state.event,
        ...action.payload.event,
        items: normalizeArray(updatedItems),
        loading_auction: true,
      },
    };
  },
  [actionTypes.UPDATE_AUCTION_ITEM]: (state, action) => {    
    return {
      ...state,
      event: { 
        ...state.event, 
        items: { 
          ...state.event.items, 
          [action.item.id]: { ...action.item } 
        } 
      },
    };
  },
  [actionTypes.SWAP_AUCTION_ITEM]: (state, action) => {
    const items = { ...state.event.items };

    items[action.items.item1.id] = { ...action.items.item1, maxBidAmount: items[action.items.item1.id].maxBidAmount };
    items[action.items.item2.id] = { ...action.items.item2, maxBidAmount: items[action.items.item2.id].maxBidAmount };

    return {
      ...state,
      event: { ...state.event, items: items },
    };
  },
  [actionTypes.UPDATE_AUCTION_ITEM_TIMER]: (state, action) => {
    console.log('UPDATE_AUCTION_ITEM_TIMER =>', action.items.output)
    const temp = state.event.items;

    action.items.output.forEach((i, idx) => {
      console.log('item =>', i, temp[idx])
      temp[idx].estimatedStart = i.estimatedStart;
      temp[idx].estimatedEnd = i.estimatedEnd;
    });

    return {
      ...state,
      event: { ...state.event, items: temp },
    };
  },
  [actionTypes.UPDATE_AUCTION_ITEM_OUTBID]: (state, action) => {
    const temp = state.event.items;

    temp[action.item.id].outbid = true;

    return {
      ...state,
      event: { ...state.event, items: temp },
    };
  },
  [actionTypes.UPDATE_AUCTION_ITEM_REMOVE]: (state, action) => {
    const temp = denormalizeEntitiesArray(state.event.items);

    const filtered = temp.filter((i) => i.id !== action.item.id);

    return {
      ...state,
      event: { ...state.event, items: normalizeArray(filtered) },
    };
  },
  [actionTypes.UPDATE_INSTA_BID]: (state, action) => ({ ...state, ...action.payload.object }),
  [actionTypes.SET_AUCTION_BLOCK_SIZE]: (state, action) => ({ ...state, ...action.payload.object }),
  [actionTypes.SET_SERVER_TIME]: (state, action) => ({ ...state, event: { ...state.event, ...action.payload.object } }),
  [actionTypes.LOADING_INITIALIZE_AUCTION]: (state) => ({ ...state, event: { ...state.event, loading_auction: false } }),
  [actionTypes.UPDATE_BUYING_POWER]: (state, action) => ({
    ...state,
    buyingPower: { ...state.buyingPower, ...action.payload },
  }),
  [actionTypes.UPDATE_ITEMS_BID_ON]: (state, action) => ({
    ...state,
    itemsBidOn: [...new Set(state.itemsBidOn.concat(action.payload))],
  }),
  [actionTypes.UPDATE_AUCTION_STATE]: (state, action) => ({ ...state, ...action.payload.object }),
  [actionTypes.GET_ITEM_BIDDING_HISTORY]: (state, action) => ({
    ...state,
    itemBiddingHistory: action.payload,
  }),
});
