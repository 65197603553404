export const INITIALIZE_LIVE_AUCTION = 'innovA/live-auction#initialize-live-auction';
export const REFRESH_LIVE_AUCTION = 'innovA/live-auction#refresh-live-auction';
export const UPDATE_AUCTION_ITEM = 'innovA/live-auction#updateAuctionItem';
export const SWAP_AUCTION_ITEM = 'innovA/live-auction#swapAuctionItem';
export const UPDATE_AUCTION_ITEM_OUTBID = 'innovA/live-auction#update-auction-item-outbid';
export const UPDATE_AUCTION_ITEM_REMOVE = 'innovA/live-auction#update-auction-item-remove';
export const UPDATE_AUCTION_ITEM_TIMER = 'innovA/live-auction#update-auction-item-timer';
export const UPDATE_INSTA_BID = 'innovA/live-auction#updateInstabid';
export const SET_AUCTION_BLOCK_SIZE = 'innovA/live-auction#set-auction-block-size';
export const SET_SERVER_TIME = 'innovA/live-auction#set-server-time';
export const LOADING_INITIALIZE_AUCTION = 'innovA/live-auction#loadingInitializeAuction';
export const UPDATE_BUYING_POWER = 'innovA/live-auction#updateBuyingPower';
export const UPDATE_ITEMS_BID_ON = 'innovA/live-auction#updateItemsBidOn';
export const UPDATE_AUCTION_STATE = 'innovA/live-auction#updateAuctionState';
export const GET_ITEM_BIDDING_HISTORY = "innovA/auction#getItemBiddingHistory";
