import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import ReactGA from "react-ga";

const ReactHelmetComponent = ({ location, pathname, content }) => {
  const [tenantName, setTenantName] = useState("");

  ReactGA.initialize("G-X7QZ2HFZ1Y");
  // ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    if (content) {
      setTenantName(content.tenantName);
    }
  }, [content]);

  const [metaDataValue, setMetadataValue] = useState({
    title: null,
    description: null,
    keywords: null,
  });

  const metadata = {
    ROOT: JSON.parse(process.env.REACT_APP_METADATA_ROOT),
    HOW_TO_BUY: JSON.parse(process.env.REACT_APP_METADATA_HOW_TO_BUY),
    HOW_TO_SELL: JSON.parse(process.env.REACT_APP_METADATA_HOW_TO_SELL),
    SEARCH: JSON.parse(process.env.REACT_APP_METADATA_SEARCH),
    ONLINE_AUCTIONS: JSON.parse(process.env.REACT_APP_METADATA_ONLINE_AUCTIONS),
    INFO_CENTER: JSON.parse(process.env.REACT_APP_METADATA_INFO_CENTER),
    OUR_APP: JSON.parse(process.env.REACT_APP_METADATA_OUR_APP),
    FINANCING: JSON.parse(process.env.REACT_APP_METADATA_FINANCING),
    DEFAULT: JSON.parse(process.env.REACT_APP_METADATA_DEFAULT)
  };


  const handleTitle = (path) => {

    switch (path) {
      case "/":
        return setMetadataValue({
          title: tenantName,
          description: metadata.ROOT.description,
          keywords: metadata.ROOT.keywords,
        });
      case "/content?how-to-buy":
        return setMetadataValue({
          title: metadata.HOW_TO_BUY.title,
          description: metadata.HOW_TO_BUY.description,
          keywords: metadata.HOW_TO_BUY.keywords,
        });
      case "/content?how-to-sell":
        return setMetadataValue({
          title: metadata.HOW_TO_SELL.title,
          description: metadata.HOW_TO_SELL.description,
          keywords: metadata.HOW_TO_SELL.keywords,
        });
      case "/search":
        return setMetadataValue({
          title: metadata.SEARCH.title,
          description: metadata.SEARCH.description,
          keywords: metadata.SEARCH.keywords,
        });
      case "/online-auctions":
        return setMetadataValue({
          title: metadata.ONLINE_AUCTIONS.title,
          description: metadata.ONLINE_AUCTIONS.description,
          keywords: metadata.ONLINE_AUCTIONS.keywords,
        });
      case "/info-center":
        return setMetadataValue({
          title: metadata.INFO_CENTER.title,
          description: metadata.INFO_CENTER.description,
          keywords: metadata.INFO_CENTER.keywords,
        });
        case "/content?our-app":
          return setMetadataValue({
            title: metadata.OUR_APP.title,
            description: metadata.OUR_APP.description,
            keywords: metadata.OUR_APP.keywords,
          });
          case "/content?financing":
            return setMetadataValue({
              title: metadata.FINANCING.title,
              description: metadata.FINANCING.description,
              keywords: metadata.FINANCING.keywords,
            });
      default:
        return setMetadataValue({
          title: metadata.DEFAULT.title,
          description: metadata.DEFAULT.description,
        });
    }
  };

  useEffect(() => {
    const path =  pathname + location.search
    handleTitle(path);
  }, [location]);

  return (
    <Helmet>
      <title>{metaDataValue.title}</title>
      <meta name="description" content={metaDataValue.description} />
      <meta name="keywords" content={metaDataValue.keywords} />
    </Helmet>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  content: state.authorization.content,
  location: state.router.location
});

export default connect(mapStateToProps, null)(ReactHelmetComponent);
